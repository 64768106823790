'use client'
import { AxiosError } from 'axios'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { type State } from 'wagmi'
import { PrivyProvider } from '@azuro-org/sdk-social-aa-connector'
import { constants } from 'helpers'
import iconImage from 'src/app/icon.png'
import config, { privyConfig } from './config'
import { SmartWalletsProvider } from '@privy-io/react-auth/smart-wallets'

const noRetryErrorCode = [ 404 ]

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      placeholderData: <T extends any>(prev: T) => prev,
      retry: (retryCount, error) => {
        if (error instanceof AxiosError) {
          const status = error?.response?.status

          if (status && noRetryErrorCode.includes(status)) {
            return false
          }
        }

        return retryCount < 3
      },
    },
  },
})

const WagmiProvider: React.CFC<{ initialState?: State }> = (props) => {

  const { children, initialState } = props
  return (
    <QueryClientProvider client={queryClient}>
      <PrivyProvider
        appId='cm33sjdkf0a8fl0x18jf5oqrq'
        privyConfig={privyConfig}
        wagmiConfig={config}
        initialWagmiState={initialState}
      >
        <SmartWalletsProvider 
          config={{
            paymasterContext: {
              mode: "SPONSORED",
              calculateGasLimits: true,
              expiryDuration: 300,
              sponsorshipInfo: {
                webhookData: {
                  
                },
                smartAccountInfo: {
                  name: "BICONOMY",
                  version: "1.0.0",
                },
              },
            },
          }}
        >
          {children}
        </SmartWalletsProvider>
      </PrivyProvider>
    </QueryClientProvider>
  )
}

export default WagmiProvider
